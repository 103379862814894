<template>
  <div>
    <b-table-custom :table-data="dataObjectList"
                    :table-columns="columns"
                    :is-data-loading="isTableDataLoading"
                    @selected-item-onchange="selectedItemOnChange">

    </b-table-custom>
  </div>
</template>

<script>

import InputHelper from "@/mixins/utils/inputHelper";
import DataRequester from "@/mixins/utils/dataRequester";
import DataObjectList from "@/mixins/utils/dataObjectList";

import BTableCustom from "@/views/components/table/BTableCustom.vue";
import proxyService from "@/mixins/utils/axios/proxy.service";

export default {
  name: "ProxyDetail",
  mixins: [InputHelper, DataRequester, DataObjectList],
  components: {
    BTableCustom
  },
  data () {
    return {
      columns: [
        {key: 'ip'},
        {key: 'port'},
        {key: 'username'},
        {key: 'password'},
        {key: 'type'},
        // {key: 'region'},
        {key: 'start_date'},
        {key: 'end_date'},
      ],
      isTableDataLoading:  false
    }
  },
  created() {
    this.getProxyDetailList()
  },
  methods: {
    selectedItemOnChange(selectedItems) {
      this.dataObjectSelectedList = selectedItems
    },

    getProxyDetailList() {
      let self = this
      let proxyServiceId = this.$route.params.id
      this.isTableDataLoading = true
      this.runApi(proxyService.getProxyDetailList(proxyServiceId), function (result) {
        self.dataObjectList = result.data
      }, undefined, function () {
        self.isTableDataLoading = false
      })
    }
  }
}
</script>
